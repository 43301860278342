import BusinessTradeRegistrationService from '../services/business-trade-registrations-service';

export const businessTradeRegistration = {
  namespaced: true,
  state: {
    tradeRegistrations: null,
  },
  actions: {
    listTradeRegistrations({ commit }, schemeBusinessId) {
      commit('setIsBusy', true, { root: true });
      return BusinessTradeRegistrationService.list(schemeBusinessId)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setTradeRegistrations', result.model);
          return Promise.resolve(result);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    createTradeRegistration({ commit, dispatch }, data) {
      commit('setIsBusy', true, { root: true });
      return BusinessTradeRegistrationService.create(data)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          const { message } = result;

          commit('appendTradeRegistration', result.model);

          setTimeout(() => {
            dispatch('business/refreshBusinessDetails', data.schemeBusinessId, { root: true });
          }, 3000);

          return Promise.resolve(message);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    updateTradeRegistration({ commit, dispatch }, data) {
      return BusinessTradeRegistrationService.update(data)
        .then((result) => {
          commit('amendTradeRegistrations', result.model);
          const { message } = result;

          setTimeout(() => {
            dispatch('business/refreshBusinessDetails', data.schemeBusinessId, { root: true });
          }, 3000);

          return Promise.resolve(message);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setTradeRegistrations(state, newTradeRegistrations) {
      state.tradeRegistrations = newTradeRegistrations;
    },
    appendTradeRegistration(state, newTradeRegistration) {
      state.tradeRegistrations.push(newTradeRegistration);
    },
    amendTradeRegistrations(state, tradeRegistration) {
      state.tradeRegistrations
        .filter((tr) => tr.tradeId === tradeRegistration.tradeId)
        .forEach((tr) => {
          tr.fromDate = tradeRegistration.fromDate;
          tr.toDate = tradeRegistration.toDate;
          tr.tradeCode = tradeRegistration.tradeCode;
          tr.schemeBusinessId = tradeRegistration.schemeBusinessId;
          tr.tradeId = tradeRegistration.tradeId;
          tr.certificateId = tradeRegistration.certificateId;
        });
    },
    setTrade(state, newTradeRegistration) {
      state.tradeRegistration = newTradeRegistration;
    },
  },
};
